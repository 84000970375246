// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA
import axios from "axios";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
  console.log(process.env.NODE_ENV);
  console.log(config);
  if ("serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
    if (isLocalhost) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      checkValidServiceWorker(swUrl, config);

      // Add some additional logging to localhost, pointing developers to the
      // service worker/PWA documentation.
      navigator.serviceWorker.ready.then((registration) => {
        //initializeNotification(registration, config);
        console.log(
          "This web app is being served cache-first by a service " +
            "worker. To learn more, visit https://cra.link/PWA"
        );
      });
    } else {
      // Is not localhost. Just register service worker

      registerValidSW(swUrl, config);
      navigator.serviceWorker.ready.then((registration) => {
        //initializeNotification(registration, config);
        console.log(
          "This web app is being served cache-first by a service " +
            "worker. To learn more, visit https://cra.link/PWA"
        );
      });
    }
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      console.log(
        "ServiceWorker registration successful with scope: ",
        registration.scope
      );
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                "New content is available and will be used when all " +
                  "tabs for this page are closed. See https://cra.link/PWA."
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log("Content is cached for offline use.");

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error("Error during service worker registration:", error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then((response) => {
      console.log(response);
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

export function initializeNotification(swRegistration, config) {
  const { user, publicKey } = config;
  console.log(config);
  if (!("Notification" in window)) {
    alert("NOT SUPPORTED NOTIFICATION");
    console.log("Notifications not supported in this browser");
    return;
  }
  if (!("PushManager" in window)) {
    alert("NOT SUPPORTED PushManager");
    console.log("Notifications not supported in this browser");
    return;
  }

  // If user opted-in any of the reminder, get subscription data and send it to the server / database.
  let isSubscribed = false;
  if (true) {
    swRegistration.pushManager.getSubscription().then((subscription) => {
      isSubscribed = subscription !== null;

      if (isSubscribed) {
        updateSubscriptionOnServer(user, subscription);
        console.log("User IS subscribed.");
      } else {
        subscribeUser(swRegistration, user, publicKey);
      }
    });
  }
  // else {
  //   //If the user outed-out the reminder, unscribe the user.
  //   unsubscribeUser(swRegistration, user);
  // }
}

function subscribeUser(swRegistration, user, publicKey) {
  //Prompt user permission for notification
  Notification.requestPermission().then((result) => {
    console.log("hie rbegin");
    alert(`Notification permission status: ${result}`);
    if (result === "granted") {
      const applicationServerPublicKey = publicKey;

      const applicationServerKey = urlB64ToUint8Array(
        applicationServerPublicKey
      );

      swRegistration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey,
        })
        .then((subscription) => {
          console.log("User is subscribed:", subscription);

          updateSubscriptionOnServer(user, subscription);
        })
        .catch((err) => {
          if (result === "denied") {
            alert("Permission for notifications was denied");
            console.warn("Permission for notifications was denied");
          } else {
            alert("Failed to subscribe the user: ", err);
            console.error("Failed to subscribe the user: ", err);
          }
        });
    }
  });
  console.log("HIERR EIND ");
  //If the browser has a permission, process subscribing the user.
}

function unsubscribeUser(swRegistration, user) {
  let userSubsctiption;
  swRegistration.pushManager
    .getSubscription()
    .then((subscription) => {
      if (subscription) {
        userSubsctiption = subscription;
        return subscription.unsubscribe();
      }
    })
    .catch((err) => {
      console.log("Error unsubscribing", err);
    })
    .then(() => {
      //If user already have subscriotion with the app, remove the data from the database.
      if (userSubsctiption) {
        updateSubscriptionOnServer(user, userSubsctiption, null);
      }
      console.log("User is unsubscribed");
    });
}

async function updateSubscriptionOnServer(
  userId,
  subscription,
  unsubscribe = "No"
) {
  // Here's where you would send the subscription to the application server/database
  //if the action is subscribe. make POST request to the server with user's subscription data.
  try {
    let requestBody = {
      webshop_token: userId,
      subscription: subscription,
    };
    if (unsubscribe) {
      return axios.post(`subscribe/`, requestBody).then((result) => result);

      //if the action is unsubscribe. make DELETE request to remove user's subscription from database.
    } else {
      let requestBody = {
        webshop_token: userId,
      };
      return axios.post(`unsubscribe/`, requestBody);
      console.log("User is not subscribed");
    }
  } catch (error) {
    console.log("Update Subscription failed", error);
  }
}

//parsing the VAPID key to correct format.
function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String?.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData?.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
