import axios from "axios";
import React, { useEffect, useState } from "react";
import "./App.css";
import Michel from "./images/Michel.png";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

axios.defaults.baseURL = "https://easyapp.bestelsysteem.net/webapi";

export const identify = (url, webshop_token) => {
  const requestData = {
    url: url,
    webshop_token: webshop_token,
  };

  const header = {
    Token: "",
    Authorization: "",
  };

  return axios
    .post("/identify", requestData, { headers: header })
    .then((response) => {
      return response.data.data.identify;
    })
    .catch((err) => {
      console.log(
        "[Identify.actions.js] identify|| Could not fetch identify information. Try again later."
      );
    });
};

function App() {
  const [identifyInfo, setIdentify] = useState(null);
  useEffect(() => {
    identify("bestel.goeieete.nl").then((res) => setIdentify(res));
  }, []);

  const handleSubscription = () => {
    // Notification.requestPermission().then((res) => console.log(res))

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready
        .then((reg) => {
          console.log(reg);
          serviceWorkerRegistration.initializeNotification(reg, {
            user: identifyInfo.webshop_token,
            publicKey: identifyInfo.public_key,
          });
          // ServiceWorker is ready and registered
        })
        .catch((err) => {
          // Handle errors in registration
          console.log(err);
        });
    }
    // serviceWorkerRegistration.register({
    //   user: identifyInfo.webshop_token,
    //   publicKey: identifyInfo.public_key,
    // });

    //handleSubscription(12345);
    //serviceWorkerRegistration.register({ user: 123 });
    // fetchPushNotification("3dbcc0dc-38dd-4b44-9cd7-c2f216332dd3").then((noti) =>
    //   console.log(noti)
    // );
  };

  return (
    <div className="App">
      <header className="App-header">
        <div
          style={{
            padding: "2px",
            borderRadius: "10px",
            backgroundColor: "rgba(250,250,250,0.9)",
            width: "90vw",
          }}
        >
          <h2 style={{ color: "black" }}>Hieroglyph master v12</h2>

          <img src={Michel} className="App-logo" alt="logo" />

          <p style={{ color: "black", fontWeight: "bold" }}>
            {" "}
            PWA Push notification poc BEUKK
          </p>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginBottom: "40px",
              //padding: "10px",
            }}
          >
            <div
              className="perl-poem"
              style={{
                //fontFamily: "'Courier New', monospace",
                textAlign: "left",
                color: "black",
              }}
            >
              In ancient lines of Perl, a tale untold,
              <br />
              Like hieroglyphs, its mysteries unfold.
              <br />
              Scripted scrolls, cryptic, old, and wise,
              <br />
              In digital depths, its essence lies.
              <br />
              <br />
              A language born from epochs past,
              <br />
              Where symbols dance and shadows cast.
              <br />
              Each glyph a key to hidden doors,
              <br />
              Perl, the script that time adores.
            </div>
          </div>
          <button onClick={() => handleSubscription()}>Notify me</button>
        </div>
        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
